html,
body,
#root {
  height: 100%;
}
.form-group {
  margin-bottom: 1rem;
}
.input-group > .form-control,
.input-group > .form-file,
.input-group > .form-select {
  /*  width: auto !important;
  flex: inherit !important; */
}

.btn {
  box-shadow: none !important;
}

#root > div {
  height: 100%;
}

.card-footer {
  background: none;
}

#react-admin-title {
  display: flex;
  align-items: baseline;
}

.sidebar {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.sidebar.hide {
  height: 0;
  opacity: 0;
}

.MuiToolbar-root .filter-field {
  align-items: center !important;
  margin-right: 1em;
}

.MuiToolbar-root .filter-field .form-label {
  margin: 0;
  margin-right: 0.5em;
}

.MuiToolbar-root > form {
  align-items: center !important;
  margin-top: 0;
}

.MuiToolbar-root > .MuiToolbar-root {
  align-items: center;
  align-self: center;
  padding: 0 !important;
}

.MuiToolbar-root .filter-field .MuiButtonBase-root {
  padding: 0;
  margin-right: 0.5em;
}

.card-header i {
  font-size: 16px !important;
}

.card-body .simple-form div {
  padding: 0 !important;
}

.page-link {
  cursor: pointer;
}

.card-footer {
  border-top: none !important;
}

.nav .nav .nav-item {
  padding-left: 1.5rem;
}

.wrapper-root {
  height: 100%;
}

.previews img {
  max-width: 100%;
}
.previews > div {
  width: 100%;
}

.MuiCollapse-wrapperInner ul.nav li a {
  font-size: 14px !important;
}

.btn-outline-primary.active {
  background-color: inherit;
}

@media (min-width: 200px) {
  .sidebar {
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .sidebar {
    padding: 100px 0 0 !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
}
